import React from 'react';
import { Link } from '@chakra-ui/react';
import styled from 'styled-components';

const DisplayMenu = styled.div`
  display: flex;
  column-gap: 24px;
  z-index: 999 !important;

  @media only screen and (max-width: 456px) {
    column-gap: 12px;
    margin: 1rem auto auto auto;
  }

  @media only screen and (max-width: 400px) {
    column-gap: 12px;
  }
`;

const Menu = () => {
  return (
    <DisplayMenu>
      <Link
        color="#d7d7d7"
        fontSize="0.9rem"
        href="/#whatwerebuilding"
        _focus={{ outline: 'none !important'}}
      >
        What we're building
      </Link>
      <Link 
        color="#d7d7d7"
        fontSize="0.9rem"
        href="/#team"
        _focus={{ outline: 'none !important'}}
      >
        Team
      </Link>
      <Link
        color="#d7d7d7"
        fontSize="0.9rem"
        href="/#investors"
        _focus={{ outline: 'none !important'}}
      >
        Investors
      </Link>
      <Link
        color="#d7d7d7"
        fontSize="0.9rem"
        href="/#careers"
        _focus={{ outline: 'none !important'}}
      >
        Careers
      </Link>
    </DisplayMenu>
  );
}

export default Menu;
