import React from 'react';
import { Box } from '@chakra-ui/react';
import styled from 'styled-components';

const Container = styled(Box)`
  margin-bottom: 5rem;
  padding-top: 1rem;
`;

const Heading = styled.h1`
  text-align: center;
  color: white;
  font-size: 2.5rem;
  line-height: var(--header-line-height);
  font-weight: 550;
  letter-spacing: -1.2px;
`;

const Copy = styled.p`
  font-size: 1.15rem;
  color: #fff;
  margin-top: 2rem;
  width: 90%;
  margin-left: 2rem;

  @media only screen and (max-width: 499px) {
    width: 100%;
    margin-left: 0rem;
  }
`;

const Team = () => {
  return (
    <Container zIndex="999" id="team">
      <Heading>Team</Heading>
      <Copy>
        Andalusia Labs is one of the most impactful teams in blockchain leveraging deep experience
        from building massively scalable software systems, AI, fintech, and blockchain products at
        leading companies like Coinbase, Google, AWS, Twitter, Social Capital, Goldman Sachs, and
        High-Frequency Trading Firms.
      </Copy>
      <Copy>
        Backed by the best investors and institutions in the world, Andalusia Labs builds software
        that manage billions of dollars of digital assets for innovative companies around the globe.
      </Copy>
      <Copy>
        The team is rapidly expanding and actively looking for exceptional talent from around the world.
        If you believe you may be a good fit, please take a look at our roles below or contact us directly
        at info@andalusia-labs.com.
      </Copy>
    </Container>
  );
}

export default Team;
