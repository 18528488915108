import React from 'react';
import Text from './Text';
import Products from './Products';
import styled from 'styled-components';

const FlexBox = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin-bottom: 6rem;
  padding-top: 1rem;
`;

const WhatWereBuilding = () => {
  return (
    <FlexBox id="whatwerebuilding">
      <Text />
      <Products />
    </FlexBox>
  );
}

export default WhatWereBuilding;
