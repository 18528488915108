import React from 'react';
import { Box } from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons'
import karak from '../../assets/karak-frame.png';
import styled from 'styled-components';

const ParentDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  z-index: 999 !important;
`;

const KarakContainer = styled.div`
  display: flex;
  border-radius: 5px;
   border: 1px solid rgba(226, 94, 5, 0.25);
  background: #070707;
  margin-bottom: 0.5rem;
  height: 100%;

  @media only screen and (max-width: 850px) {
    display: none;
  }
`;

const KarakFrame = styled.div`
  width: 65%;
`;

const KarakImage = styled.img`
  width: 100% !important;
  height: 100%;
  border-radius: 5px 0px 0px 5px;
`;

const KarakInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
  padding: 1rem;
  margin: auto !important;
  width: 29%;

  @media only screen and (max-width: 1419px) {
    width: 32%;
  }
`;

const HideDiv = styled.div`
  display: none;
  @media only screen and (max-width: 850px) {
    display: block;
  }
`;

const Container = styled(Box)`
  display: flex;
  justify-content: center;
  column-gap: 0.5rem;

  @media only screen and (max-width: 872px) {
    column-gap: 0rem;
    flex-direction: column;
    align-items: center;
    row-gap: 1rem;
  }
`;

const SubContainer = styled(Box)`
`;

const Frame = styled.div`
  height: 18rem;

  @media only screen and (max-width: 1490px) {
    height: 17rem;
  }

  @media only screen and (max-width: 993px) {
    height: 15rem;
  }

  @media only screen and (max-width: 872px) {
    height: 12rem;
  }
`;

const Image = styled.img`
  width: 100% !important;
  border-radius: 5px 5px 0px 0px;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
  padding: 1rem;
  margin-top: 2rem;
  margin-bottom: 2rem;

  @media only screen and (max-width: 1490px) {
    margin-top: 1rem;
  }

  @media only screen and (max-width: 1311px) {
    margin-top: 0rem;
  }

  @media only screen and (max-width: 993px) {
    margin-top: 0rem;
    margin-bottom: 1rem;
  }
`;

const Heading = styled.p`
  font-size: 1rem;
  color: #f6f6fd;
  font-weight: 700;
`;

const Text = styled.p`
  font-size: 0.8rem;
  color: #bdbdbd;
`;

const LearnMore = styled.div`
  display: flex;
  padding: 0.3rem 0.5rem;
  justify-content: center;
  align-items: center;
  gap: 5px;
  border-radius: 100px;
  background: #292929;
  backdrop-filter: blur(50px);
  width: 7.5rem;

  &:hover {
    text-decoration: underline;
    color: white;
    cursor: pointer;
  }
`;

const SubText = styled.p`
  font-size: 0.75rem;
  color: #bdbdbd;
  font-weight: 700;
`;


const Products = () => {
  return (
    <ParentDiv>
      <KarakContainer>
        <KarakFrame>
          <KarakImage src={karak} alt="karak" />
        </KarakFrame>
        <KarakInfoContainer>
          <Heading style={{ fontSize: '1.25rem' }}>Karak</Heading>
          <Text style={{ fontSize: '1rem' }}>Karak is building the universal security layer for digital assets.</Text>
          <LearnMore
            style={{ width: '9rem' }}
            onClick={() => {
              window.open('https://karak.network', '_new');
            }}
          >
            <SubText style={{ fontSize: '1rem' }}>Learn more</SubText>
            <ExternalLinkIcon w={3} h={3} color="#bdbdbd" />
          </LearnMore>
        </KarakInfoContainer>
      </KarakContainer>
      <Container zIndex="999">
        <HideDiv>
          <SubContainer className="sub-section-container">
            <Frame>
              <Image src={karak} alt="subsea" />
            </Frame>
            <InfoContainer>
              <Heading>Karak</Heading>
              <Text>Karak is building the universal security layer for digital assets</Text>
              <LearnMore
                onClick={() => {
                  window.open('https://karak.network', '_new');
                }}
              >
                <SubText>Learn more</SubText>
                <ExternalLinkIcon w={3} h={3} color="#bdbdbd" />
              </LearnMore>
            </InfoContainer>
          </SubContainer>
        </HideDiv>
      </Container>
    </ParentDiv>
  );
}

export default Products;
