import React from 'react';
import { Box } from '@chakra-ui/react';
import styled from 'styled-components';

const Container = styled(Box)`
  margin-bottom: 2rem;
`;

const HeadingTitle = styled.h1`
  text-align: center;
  color: white;
  font-size: 2.5rem;
  line-height: var(--header-line-height);
  font-weight: 550;
  letter-spacing: -1.2px;
`;

const Text = () => {
  return (
    <Container zIndex="999">
      <HeadingTitle>What We're Building</HeadingTitle>
    </Container>
  );
}

export default Text;
