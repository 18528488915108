import './App.css';
import {ThemeProvider, CSSReset, theme} from '@chakra-ui/react';
import styled from 'styled-components';
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';
import grid from './assets/al-grid.png';
import Header from './components/Header/Header';
import Intro from './components/Intro/Intro';
import WhatWereBuilding from './components/WhatWereBuilding/WhatWereBuilding';
import Team from './components/Team/Team';
import Investors from './components/Investors/Investors';
import Careers from './components/Careers/Careers';
import Footer from './components/Footer/Footer';
import Terms from './components/Terms/Terms';
import Privacy from './components/PrivacyPolicy/PrivacyPolicy';

const Main = styled.div`
`;

const Container = styled.div`
  position: relative;
`;

const GridBlur = styled.img`
  position: absolute;
  width: 100vw;
`;

function App() {
  return (
    <>
      <ThemeProvider theme={theme}>
        <CSSReset />
        <Main className="main">
          <Router>
            <Switch>
              <Route exact path="/">
                <GridBlur src={grid} alt="grid" />
                <Container className="container">
                  <Header />
                  <Intro />
                  <GridBlur src={grid} alt="grid" />
                  <WhatWereBuilding />
                  <GridBlur src={grid} alt="grid" />
                  <Team />
                  <GridBlur src={grid} alt="grid" />
                  <Investors />
                  <Careers />
                  <Footer />
                </Container>
              </Route>
              <Route exact path="/terms">
                <Container className="container">
                  <Header />
                    <Terms />
                  <Footer />
                </Container>
              </Route>
              <Route exact path="/privacy">
                <Container className="container">
                  <Header />
                    <Privacy />
                  <Footer />
                </Container>
              </Route>
            </Switch>
          </Router>
        </Main>
      </ThemeProvider>
    </>
  );
}

export default App;
