import React from 'react';
import { Stack } from '@chakra-ui/react';
import { Link as RouteLink } from 'react-router-dom';
import logo from '../../assets/logo.svg';
import styled from 'styled-components';
import Menu from './Menu';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
  margin-bottom: 3rem;

  @media only screen and (max-width: 456px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Logo = styled.img`
  width: 2rem;
`;

const Header = () => {
  return (
    <Container>
      <RouteLink to="/" style={{zIndex: 999}}>
        <Stack spacing="0.5rem" direction="row" align="center" _hover={{ cursor: 'pointer' }}>
          <Logo src={logo} />
        </Stack>
      </RouteLink>
      <Menu />
    </Container>
  );
}

export default Header;
