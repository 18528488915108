import React from 'react';
import { Box } from '@chakra-ui/react';
import styled from 'styled-components';
import ap from '../../assets/andalusia-labs.svg';

const Container = styled(Box)`
  margin-top: 2rem;
  margin-bottom: 5rem;
`;

const Heading = styled.img`
  width: 38rem;
`;

const SubHeading = styled.p`
  font-size: var(--h1-font-size);
  font-weight: 700;
  text-align: center;
  color: #bdbdbd;
`;

const Info = () => {
  return (
    <Container className="section-container" zIndex="999">
      <Heading src={ap} alt="ap" />
      <SubHeading>Building the future of the internet</SubHeading>
    </Container>
  );
}

export default Info;
